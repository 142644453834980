import React, {useRef, useEffect} from 'react'


export const Message = ({ message, i}) => {
    
    const ref = useRef();
    
    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [message]);

    return (
        <div  ref={ref} className={`message ${message.source}`} key={i} >
            <div className="messageInfo"></div>
            <div className="messageContent">
                <div>{message.message}
                    <br/>
                    <span className="timestamp">{
                        message?.createdOn?.seconds &&
                        new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(message?.createdOn.seconds*1000))
                        }</span>
                </div>
            </div>
        </div>
        
    )
}

export default Message;