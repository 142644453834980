import {
  createContext,
  useContext,
  useReducer,
} from "react";
import { AuthContext } from "./AuthContext";

export const ChatContext = createContext();

export const ChatContextProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const INITIAL_STATE = {
    messages: [],
    orders: [],
    userId: ''
  };

  const chatReducer = (state, action) => {

    switch (action.type) {
      case "CHANGE_USER":
        return {
          payload: action.payload,
          messages: action.payload?.data().messages,
          userId: action.payload?.id
        };
      case "UPDATE_CHAT":
        return {
            payload: action.payload,
            messages: action.payload?.data().messages,
            userId: action.payload?.id
          };
      case "INITIAL_STATE":
        console.log('test')
        return INITIAL_STATE;

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ data:state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
  
};