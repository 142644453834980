// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCZW1Dq3xAO4iCGbBr3qKcnguNkWXvBAzo",
  authDomain: "temporal-web-373616.firebaseapp.com",
  projectId: "temporal-web-373616",
  storageBucket: "temporal-web-373616.appspot.com",
  messagingSenderId: "973217376307",
  appId: "1:973217376307:web:8380c3abf8be848e245b46"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth()
export const db = getFirestore()