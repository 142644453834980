import React, {useState, useContext, useEffect} from 'react'
import { ChatContext } from '../context/ChatContext';
import { collection, addDoc, Timestamp, query, where, onSnapshot, limit, orderBy} from "firebase/firestore";
import {db} from "../firebase";

export const Input = () => {

  const { data } = useContext(ChatContext);

  const [url, setUrl] = useState(''); 
  const [inputText, setInputText] = useState('');
  
  useEffect(() => {
    
    const getLastMessage = () => {
    const q = query(
      collection(db, `chats/${data.userId}/messages`),
      where('source','in', ['order', 'user']),
      orderBy('createdOn', 'desc'),
      limit('1')
    )

    const unsub = onSnapshot(q, (snapshot) => {
      let currentLastMessage = snapshot.docs.pop().data();
      //console.log(currentLastMessage)
      // snapshot.docs.forEach( (doc, key) => {
      //   arr.push( doc.data())
      // });

      // setMessages(arr);
      setUrl(currentLastMessage?.replyLink 
              || currentLastMessage?.url 
              || `https://www.ebay.com/contact/sendmsg?recipient=${data.userId}`
              //|| data.messages[data.messages.length-1]?.message.url
              );
      return () => {
        unsub();
      };
    })
  }

  data.userId && getLastMessage()
    // const unSub = onSnapshot(doc(db, "chats", data.userId), (doc) => {
    //   doc.exists() && setMessages(doc.data().messages);
    // });

    // return () => {
    //   unSub();
    // };
  }, [data]);

  const handleOnChange = (e) => {
    setInputText(e.target.value)
  }

  const handleSend = () => {

    let type = url.includes('contact') ? 'order' : 'message'
    const req = {
      url,
      text: inputText,
      type
    }

    addDoc(
      collection(db,`chats/${data.userId}/messages`), {
        message: inputText,
        source: 'agent',
        type: 'message',
        createdOn: Timestamp.now(),
        req
    })

    setInputText('');
    
  }

  const handleEnter = (e) => {
    e.code  === "Enter" && handleSend(e)
  }

  return (
    <div className='input'>
        <input type="text" value={inputText} onChange={handleOnChange} onKeyDown={handleEnter} />
        <div className="send">
            <button onClick={handleSend} disabled={inputText.length===0}>Send</button>
        </div>
    </div>
  )
}

export default Input;