import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "../firebase"
import { doc, setDoc } from "firebase/firestore";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';

const Register = () => {

    let [loading, setLoading] = useState(false);
    const [err, setfErr] = useState(false);
    const navigate = useNavigate()
    const handleSubmit = async (e) => {

        setLoading(!loading);
        e.preventDefault();
        const displayName = e.target[0].value;
        const email = e.target[1].value;
        const password = e.target[2].value;
        
        try {
            // eslint-disable-next-line no-unused-vars
            const res = await createUserWithEmailAndPassword(auth, email, password).then(
                async res => {
                    await updateProfile(res.user, { displayName });
                    await setDoc(doc(db, "agents", res.user.uid), {
                        uid: res.user.uid,
                        displayName: displayName, email: email
                    });
                }
            );
            
            navigate('/');

        } catch (err) {
            console.log(err);
            setfErr(true);
            setLoading(!loading);
        }

    }

    return (
        <div className="formContainer">
            <div className="formWrapper">
            <PacmanLoader
                color='#7b96ec'
                loading={loading}
                cssOverride={ {} }
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
                <span className="logo">Ebay Chat</span>
                <span className="title">Register</span>
                <form onSubmit={handleSubmit}>
                    <input type="text" placeholder='display name' />
                    <input type="email" placeholder='email' />
                    <input type="password" placeholder='password' />
                    <button>Sign Up</button>
                </form>
                {err && <span>Something went wrong: {err}</span>}
                <p>Already have an account? <Link to='/login'>Login</Link></p>
            </div>
        </div>
    )
}

export default Register;
