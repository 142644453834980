import React, { useContext, useEffect, useState } from 'react'
import {
  collection,
  query,
  onSnapshot, orderBy, limit} from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import {db} from "../firebase"
export const Chats = () => {

  const [selected, setSelected] = useState(null);
  const [chats, setChats] = useState([])
  const [currentChatId, setCurrentChatId] = useState('');
  const  { currentUser } = useContext(AuthContext);
  const  { dispatch } = useContext(ChatContext);
  
  useEffect(() => {

    const getChats = () => {
      const q = query(
        collection(db, "chats"),
        orderBy("lastUpdate", "desc"),
        limit(15),
      );
      const unsub = onSnapshot(q, (snapshot) => {
        setChats(snapshot.docs)

        if(currentChatId) {
          let currentChat = snapshot.docs.filter((v,k) => {
            return v.id === currentChatId;
          })[0];
          dispatch({type: "UPDATE_CHAT", payload: currentChat})
        }
      return () => {
          unsub();
        };
      })
    }

    currentUser && getChats();
  }, [currentUser, currentChatId]);

  const handleSelect = (data) => {
    setCurrentChatId(data.id);
    setSelected(data.id)
    dispatch({type: "CHANGE_USER", payload:data})
  }

  const getLastMesssage = ( data ) => {
    let lastMessage = data.data().lastMessage || '...';//data.data().messages[data.data().messages.length-1].message.text || data.data().messages[data.data().messages.length-1].message.substr(0,20)+'...' ;

    return lastMessage;
  }

  return (
    <div className="chats" >
      { Object.entries(chats)?.map(([k, data]) => ( 
        
        <div className={"userChat" + (data.id === selected ? " selected" : "")  } key={data.id} 
        onClick={()=>handleSelect( data )}>
          <div className="userChatInfo" >
            <span>{data.id}</span>
            <p>
              {getLastMesssage(data)}
              </p>
          </div>
      </div>
        ))}
    </div>
  )
}

export default Chats;