import React, { useContext } from 'react'
import { Input } from './Input';
import { Messages } from './Messages';
import { ChatContext } from "../context/ChatContext";
import {ActionList} from '@primer/react'
import { FileZipIcon } from '@primer/octicons-react'
import { db } from "../firebase";
import { doc, Timestamp, setDoc, query, collection, orderBy, getDocs, deleteDoc} from "firebase/firestore"
export const Chat = () => {

  const {data} = useContext(ChatContext)
  const {dispatch} = useContext(ChatContext)

  // useEffect(() => { 

  // });

  const archiveChats = async (userId) => {
    const q = query(
      collection(db, `chats/${userId}/messages`)
      , orderBy('createdOn', 'asc')
    )

    let res = await getDocs(q)
    res.forEach((d, key) => {
      setDoc(doc(db, `archive/${userId}/messages`, d.id), d.data());
    })

    deleteDoc(doc(db, `chats/${userId}`));
    

  }

  const handleArchive = async (userId) => {
    

    let obj = data.payload.data()
    data['archivedOn'] = Timestamp.now();
    setDoc(
       doc(db,`archive/${userId}`), obj, {merge: true});

    await archiveChats(userId);
    window.location.reload(false);

  }

  return (
    <div className="chat" key={`${data.id}`}>
        <div className="chatInfo">
            <span>{data.userId && data.userId}</span>
            <div className="chatIcons">
            {data.userId && <ActionList>
            <ActionList.Item onSelect={event => {handleArchive(data.userId)}}>
              <FileZipIcon size={24} variant="full" />
            </ActionList.Item>
            </ActionList>}
            </div>
        </div>
        <Messages />
        <Input />    
    </div>
  )
}

export default Chat;