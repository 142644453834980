import React, { useState } from 'react';
import {auth} from '../firebase'
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {

    const [err, setfErr] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (e) =>{

        e.preventDefault();
        const email = e.target[0].value;
        const password = e.target[1].value;

        try {

           await signInWithEmailAndPassword(auth, email, password);
           navigate('/');
           
       } catch (err) {
           setfErr(true);
           console.log(err.Message);
       }

    }

    return (
        <div className="formContainer">
            <div className="formWrapper">
                <span className="logo">Ebay Chat</span>
                <span className="title">Register</span>
                <form onSubmit={handleSubmit}>
                    <input type="email" placeholder='email'/>
                    <input type="password" placeholder='password' />
                    <button >Login</button>
                    {err && <span>Something went wrong.</span>}
                    <p>Don't have an account? <Link to='/register'>Register</Link></p>
                </form>
            </div>
        </div>
    )
}

export default Login;
