import React, { useContext, useEffect, useState, useRef } from "react";
import { query, doc, collection, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { ChatContext } from "../context/ChatContext";
import Message from './Message'
import Order from './Order'
export const Messages = () => {
  
  const [messages, setMessages] = useState([]);
  const { data } = useContext(ChatContext);

  useEffect(() => {
    
    const getMessages = () => {
    const q = query(
      collection(db, `chats/${data.userId}/messages`)
      , orderBy('createdOn', 'asc')
    )

    const unsub = onSnapshot(q, (snapshot) => {
      console.log('change!');
      let arr = [];

      snapshot.docs.forEach( (doc, key) => {
        arr.push( doc.data())
      });
      
      setMessages(arr);
      return () => {
        unsub();
      };
    })
  }


  data.userId && getMessages()
    // const unSub = onSnapshot(doc(db, "chats", data.userId), (doc) => {
    //   doc.exists() && setMessages(doc.data().messages);
    // });

    // return () => {
    //   unSub();
    // };
  }, [data]);

  return (
    
    <div className='messages'>
      {
      messages && messages.map((message, i)=>(
         message.source && message.source === 'order' ? 
         <Order buyer={data.userId} message={message.message} createdOn={message.createdOn} key={i} status={message.status}/> : 
         <Message message={message} key={i}/>
      ))}
    </div>
  )
}

export default Messages;