import React, { useRef, useEffect } from 'react'
import { ExternalLink } from 'react-external-link'
import { ActionMenu, ActionList, IconButton } from '@primer/react'
import { KebabHorizontalIcon, PencilIcon } from '@primer/octicons-react'
import { doc, updateDoc} from "firebase/firestore";
import { db } from "../firebase";

export const Order = ({ buyer, message, id, createdOn, status}) => {

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  const handleMarkAsShipped = (url, id) => {
    
    let type = 'mark-as-shipped'
    
    let req = {
      type,
      url
    }

    console.log(id)

     updateDoc(
       doc(db,`chats/${buyer}/messages/${id}`), {
        
        status: 'marked-as-shipped',
        req
    }, {merge: true})

    //update document
    // axios.post(`http://35.226.99.124:3000/`,  req ).then( (res) => {

    //   console.log(res.data);

    // })

  }

  return (
    <div ref={ref} className={`message order`} key={`${id}`}>
      
      <div className="messageContent">

        <div>
        { status && <span className={`status ${status}`} >{status}</span>}
          {message.text.split(' ')[0]} <ExternalLink href={`${message?.replyLink || message.url}`} >{message.text.split(' ')[1]}</ExternalLink> {message.text.split(' ')[2]}<br />
          {message.title || ""}
          {
            message.orderItems.map((item, index) => (
              <table className="orderDetails" key={index}>
                <tbody>
                  {
                    Object.keys(item).map((k, i) => (
                      <tr className="item" key={i}><td>{k}</td><td>{item[k]}</td></tr>
                    ))
                  }
                </tbody>
              </table>
            ))
          }
          
          {<span className="timestamp">{createdOn && new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(createdOn.seconds * 1000))}</span>}
        </div>
        
      </div>
      <div className="messageInfo">
          <ActionMenu>
            <ActionMenu.Anchor>
              <IconButton icon={KebabHorizontalIcon} variant="invisible" aria-label="Open column options" />
            </ActionMenu.Anchor>
            <ActionMenu.Overlay>
              <ActionList>
                <ActionList.Item onSelect={event => handleMarkAsShipped(message?.replyLink || message.url, message.text.split(' ')[1])}>
                  <ActionList.LeadingVisual>
                    <PencilIcon />
                  </ActionList.LeadingVisual>
                  Mark as Shipped
                </ActionList.Item>
                {/* <ActionList.Item>
                  <ActionList.LeadingVisual>
                    <ArchiveIcon />
                  </ActionList.LeadingVisual>
                  Archive all cards
                </ActionList.Item> */}
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </div>
    </div>
  )
}

export default Order;